<template>
  <div
    ref="friendDetails"
    class="window-container"
    v-click-outside="onClickedOutside"
  >
    <div class="left-window">
      <div class="friends-header">
        <div class="friends-header-title">{{ $t("friends.userProfile") }}</div>
        <div class="close-container" @click="closeWindow">
          <div class="close-button flex-center">
            <span class="sprite-table-pop-up exit-icon"></span>
          </div>
        </div>
      </div>
      <div class="friends-detail">
        <div
          class="slider-part"
          @click="toggleBlockUser"
          v-if="user.id !== getSelectedUserModal.id"
        >
          <div
            class="on-part"
            v-if="user.blockedUsers.includes(getSelectedUserModal.id)"
          >
            <div class="sprite-profile-popup button-red-s"></div>
            <span class="sprite-profile-popup circle-button"></span>
          </div>
          <div class="off-part" v-else>
            <div class="sprite-profile-popup button-green-s"></div>
            <span class="sprite-profile-popup circle-button left"></span>
          </div>
          <div
            class="blocked-status"
            v-if="user.blockedUsers.includes(getSelectedUserModal.id)"
          >
            {{ $t("socialWindow.unBlock") }}
          </div>
          <div v-else class="blocked-status">
            {{ $t("socialWindow.block") }}
          </div>
        </div>
        <Avatar
          class="player-window-avatar"
          :url="getSelectedUserModal.avatar"
          :crown="getSelectedUserModal.crown"
          :lazy="false"
          size="195"
        />
        <div
          class="user-country"
          v-if="getSelectedUserModal.country"
          :style="getFlagSource(getSelectedUserModal.country)"
        ></div>

        <!-- </div> -->
        <div class="username-value">
          <span>{{ getSelectedUserModal.name }}</span>
        </div>
        <div class="input-line">
          <div class="red-content">
            <div class="profile-sprite dollar-icon"></div>
          </div>
          <div class="value chip-value">
            <p>{{ getChipFormat(getSelectedUserModal.chip) }}</p>
          </div>
        </div>
        <div class="input-line user-id">
          <div class="red-content">
            <div class="profile-sprite id-icon"></div>
          </div>
          <p class="user-id-text">{{ getSelectedUserModal.id }}</p>
          <div class="click-area flex-center">
            <div class="orange-content" @click="clipBoardId">
              <div class="profile-sprite copy-icon"></div>
              <div class="tooltip">
                <span class="tooltiptext tooltip-top" v-show="copyInfoShow">{{
                  $t("playerDetailsWindow.copied")
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="input-line">
          <div class="red-content">
            <div class="profile-sprite level-icon"></div>
          </div>
          <p class="value chip-value">
            {{ $t("playerDetailsWindow.level") }}: {{ levelNumber }}
          </p>
        </div> -->
        <div class="friend-actions" v-if="user.id !== getSelectedUserModal.id">
          <div class="message-button flex-center" @click="openSendMessage">
            <div class="chat-icon"></div>
            <span class="chat-text">{{ $t("message.sendMessage") }}</span>
          </div>
          <div
            class="add-friends-button flex-center"
            v-if="!getSelectedUserModal.isMyFriend"
            @click="addFriend"
          >
            <div
              class="add-friends-icon"
              v-if="!sendRequestBlock && !isMyFriend(getSelectedUserModal.id)"
            ></div>
            <span
              class="chat-text"
              v-if="!sendRequestBlock && !isMyFriend(getSelectedUserModal.id)"
              >{{ $t("friends.addButtonText") }}</span
            >
          </div>
          <div
            class="remove-friend flex-center"
            v-else
            @click="deleteDialog = true"
          >
            <div class="remove-friend-icon"></div>
            <span class="remove-friend-text chat-text">
              {{ $t("friends.deletefriends") }}
            </span>
          </div>
          <div class="chip-button flex-center" @click="sendChip">
            <div class="chip-icon"></div>
            <span class="chat-text">{{ $t("friends.sendChipText") }}</span>
          </div>
        </div>

        <div class="friend-desc">
          <div class="friend-desc-title">
            {{ $t("playerDetailsWindow.level") }}
          </div>
          <div class="friend-desc-number">
            {{ getLevelInfo }}
          </div>
        </div>
        <div class="friend-desc">
          <div class="friend-desc-title">{{ $t("general.leaders") }}</div>
          <div class="friend-desc-number">
            {{ getSelectedUserModal.placementInPlayers }}
          </div>
        </div>
        <div class="friend-desc">
          <div class="friend-desc-title">
            {{ $t("playerDetailsWindow.recordChip") }}
          </div>
          <div class="friend-desc-number">
            {{ getChipFormat(getSelectedUserModal.recordChip) }}
          </div>
        </div>
        <div class="friend-desc">
          <div class="friend-desc-title">
            {{ $t("playerDetailsWindow.friendsCount") }}
          </div>
          <div class="friend-desc-number">
            {{ getSelectedUserModal.friendCount }}
          </div>
        </div>
        <div class="friend-desc">
          <div class="friend-desc-title">
            {{ $t("profileSettings.statistics.biggestPotWon") }}
          </div>
          <div class="friend-desc-number">N/A</div>
        </div>
        <div class="friend-desc note" v-if="getSelectedUserModal.profileNote">
          <div class="friend-desc-title">
            {{ $t("playerDetailsWindow.profileNote") }}
          </div>
          <div class="friend-desc-number selectable-text">
            {{ getSelectedUserModal.profileNote }}
          </div>
        </div>
        <div
          class="friend-desc best"
          v-if="getBestHandCards && getBestHandCards.length > 0"
        >
          <div class="friend-desc-title">
            {{ $t("playerDetailsWindow.bestHand") }}
          </div>
          <div class="friend-desc-cards">
            <Card
              v-for="(card, cardIndex) in getBestHandCards"
              :key="'table-card-' + cardIndex"
              :suit="card.suit"
              :value="card.value"
              type="table"
            />
          </div>
        </div>
      </div>
    </div>
    <SendMessage
      :show="showSendPopUp"
      :receiverId="getSelectedUserModal.id"
      @closeWindowSendMessage="closeSendMessage"
      :outerStyle="{
        left: '100',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }"
    />
    <AreYouSureWindow
      :stepCount="1"
      :show="deleteDialog"
      @closeAreYouSure="deleteDialog = false"
      @confirm="removeFriend"
      :content="$t('deleteConfirmWindow.deletefriends')"
      :titleText="$t('socialWindow.removeFriend')"
      :name="getSelectedUserModal.name"
      :defaultText="defaultText"
      :outerLeftPx="160"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BaseWindow from "./BaseWindow";
import Avatar from "@/components/Misc/Avatar";
import Helpers from "@/mixin/Helpers";
import clipboard from "@/components/Misc/Svg/Clipboard";
import ConfirmWindow from "@/components/Misc/GlobalWindows/ConfirmWindow";

export default {
  name: "FriendDetailsWindow",
  mixins: [Helpers],
  components: {
    SendMessage: () =>
      import("@/components/Misc/GlobalWindows/ChatComponents/SendMessage.vue"),
    BaseWindow,
    Avatar,
    clipboard,
    Card: () => import("@/components/Misc/PokerTable/Cards/Card"),
    AreYouSureWindow: () => import("./AreYouSureWindow"),
    ConfirmWindow,
  },
  directives: {
    "click-outside": {
      bind: function (el, binding, vnode) {
        el.eventSetDrag = function () {
          el.setAttribute("data-dragging", "yes");
        };
        el.eventClearDrag = function () {
          el.removeAttribute("data-dragging");
        };
        el.eventOnClick = function (event) {
          var dragging = el.getAttribute("data-dragging");
          // Check that the click was outside the el and its children, and wasn't a drag
          if (!(el == event.target || el.contains(event.target)) && !dragging) {
            // call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.addEventListener("touchstart", el.eventClearDrag);
        document.addEventListener("touchmove", el.eventSetDrag);
        document.addEventListener("click", el.eventOnClick);
        document.addEventListener("touchend", el.eventOnClick);
      },
      unbind: function (el) {
        document.removeEventListener("touchstart", el.eventClearDrag);
        document.removeEventListener("touchmove", el.eventSetDrag);
        document.removeEventListener("click", el.eventOnClick);
        document.removeEventListener("touchend", el.eventOnClick);
        el.removeAttribute("data-dragging");
      },
    },
  },
  watch: {
    copyInfoShow: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.copyInfoShow = false;
          }, 1000);
        }
      },
      immediate: true,
    },
    "$store.state.modals.friendDetails": {
      handler(val) {
        if (val) {
          this.showTimer = true;
          this.timeoutId = setTimeout(() => {
            this.showTimer = false;
          }, 500);
        } else {
          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
          }
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      profileCurrentTab: "note",
      levelNumber: 0,
      showSendPopUp: false,
      sendRequestBlock: false,
      showConfirmWindow: false,
      copyInfoShow: false,
      deleteDialog: false,
      defaultText: false,
      showTimer: false,
      timeoutId: 0,
      dummyCards: [
        { index: 33, suit: "c", value: "9" },
        { index: 35, suit: "c", value: "J" },
        { index: 39, suit: "h", value: "2" },
        { index: 3, suit: "s", value: "5" },
        { index: 3, suit: "s", value: "5" },
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["getChipFormat"]),
    ...mapGetters("user", ["getLevel"]),
    ...mapGetters("friends", ["getSelectedUserModal"]),
    getBestHandCards() {
      if (
        this.getSelectedUserModal.bestPokerHand == "" ||
        this.getSelectedUserModal.bestPokerHand == "-" ||
        this.getSelectedUserModal.bestPokerHand == null
      )
        return [];
      const split = String(this.getSelectedUserModal.bestPokerHand || "").split(
        " "
      );
      if (split && split.length <= 0) return [];
      return [...split].map((item) => ({
        suit: item[1],
        value: item[0] == "T" ? "10" : item[0],
      }));
    },
    getLevelInfo() {
      if (this.getSelectedUserModal.id === this.user.id) {
        return (this.levelNumber = this.getLevel(this.user.xp).number);
      } else {
        return (this.levelNumber = this.getLevel(
          this.getSelectedUserModal.xp
        ).number);
      }
    },
  },
  methods: {
    getFlagSource(country) {
      let styles = {};
      styles.width = "100px";
      styles.height = "100px";
      styles.marginLeft = "10px";
      if (!country || country == "undefined") return styles;
      styles.backgroundImage = `url(${require("@/assets/images/flags/" +
        country.toUpperCase() +
        ".png")}`;
      styles.backgroundSize = "contain";
      styles.backgroundRepeat = "no-repeat";
      styles.backgroundPosition = "center";
      return styles;
    },
    async removeFriend() {
      if (this.getSelectedUserModal.id) {
        const friendRemoveResponse = await this.$store.dispatch(
          "friends/removeFriend",
          this.getSelectedUserModal.id
        );
        if (friendRemoveResponse) {
          this.$plugins.toast.show({
            text: this.$t("others.friendRemovedInfo"),
            duration: 2500,
          });
        }

        this.$plugins.audio.play("Button");
      } else {
        this.$plugins.toast.show({
          text: this.$t("others.notFoundFriend"),
          duration: 2500,
        });
      }
    },
    onClickedOutside($e) {
      if (this.showTimer) {
        return;
      }
      // if (this.$store.state.modals.friendDetails) {
      //   $e.preventDefault();
      //   this.closeWindow();
      //   return;
      // }

      if ($e && !this.showSendPopUp && !this.$store.state.modals.sendChip) {
        // if (
        //   $e.path &&
        //   $e.path.filter((item) => item.className.includes("seat-me")).length >
        //     0 &&
        //   !this.$store.state.modals.friendDetails
        // ) {
        //   return;
        // }
        if (this.$store.state.modals.friendDetails) {
          this.closeWindow();
          $e.preventDefault();
          return;
        }
      }
    },
    clipBoardId() {
      this.$plugins.clipboard.write({
        type: "string",
        value: String(this.getSelectedUserModal.id),
      });
      this.copyInfoShow = true;
      this.$adjust.trackEvent("OtherPlayerCopyIdClick");
    },
    async removeFriend() {
      await this.$store.dispatch(
        "friends/removeFriend",
        this.getSelectedUserModal.id
      );
      this.closeConfirmWindow();
      this.$emit("fetchFriends");
      this.$store.commit("modals/hide", "friendDetails");
    },
    deleteConfirm() {
      this.$data.showConfirmWindow = true;
    },
    closeConfirmWindow() {
      this.$data.showConfirmWindow = false;
    },
    closeWindow() {
      if (this.deleteDialog) {
        return;
      }
      this.$refs.friendDetails.classList.add("closeAnimate");
      setTimeout(() => {
        this.$store.commit("modals/hide", "friendDetails");
        this.$store.commit("friends/clearSelectedUserModal");
        if (this.$store.state.modals.lastOpenedModal !== "") {
          this.$store.commit(
            "modals/show",
            this.$store.state.modals.lastOpenedModal
          );
          this.$store.state.modals.lastOpenedModal = "";
        }
      }, 700);
    },
    toggleBlockUser() {
      this.$store.dispatch("chat/toggleBlockUser", {
        userId: this.getSelectedUserModal.id,
      });
    },
    handleTabs(tab) {
      this.$data.profileCurrentTab = tab;
    },
    getBestHandCardClasses(card) {
      const value = String(card.value).replace("T", "10");
      return String(card.suit + value).toLowerCase();
    },
    async copyToClipboard(value) {
      await this.$plugins.clipboard.write({
        type: "string",
        value: String(value),
      });
      this.$refs.popover.style.visibility = "visible";
      setTimeout(() => {
        this.$refs.popover.style.visibility = "hidden";
      }, 2000);
      this.$plugins.audio.play("Button");
      return this.$plugins.toast.show({
        text: this.$t("playerDetailsWindow.copied"),
        duration: 2500,
        variant: "success",
      });
    },
    async addFriend() {
      const response = await this.$store.dispatch(
        "friends/sendFriendRequest",
        this.getSelectedUserModal.id
      );
      if (!response) {
        this.sendRequestBlock = !response;
      }
      this.sendRequestBlock = true;
      setTimeout(() => {
        this.sendRequestBlock = false;
      }, 30000);

      this.$adjust.trackEvent("OtherPlayerAddFriendClick");
    },
    sendChip() {
      const user = {
        name: this.getSelectedUserModal.name,
        id: this.getSelectedUserModal.id,
        avatar: this.getSelectedUserModal.avatar,
        crown: this.getSelectedUserModal.crown,
        chip: this.getSelectedUserModal.chip,
      };
      this.$store.commit("chipTransfer/setSendChipWindowData", user);
      this.$store.commit("modals/show", "sendChip");
      this.closeWindow();
      // this.$store.commit("modals/hide", "friendDetails");
      // this.$store.state.modals.lastOpenedModal = "friendDetails";
      this.$adjust.trackEvent("OtherPlayerSendChipClick");
    },
    openSendMessage() {
      this.showSendPopUp = true;
      this.$adjust.trackEvent("OtherPlayerChatClick");
    },
    closeSendMessage() {
      this.showSendPopUp = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.window-container {
  position: fixed;
  top: 180px;
  left: 320px;
  height: 1080px;
  width: 700px;
  will-change: auto;
  z-index: 999;
  animation-name: openAnimation;
  animation-duration: 0.7s;
  .left-window {
    background: rgb(43, 9, 15, 0.95);
    width: 700px;
    height: 1080px;
    overflow: hidden;
  }
  .friends-header {
    height: 100px;
    background-color: #3d0b14;
    &-title {
      height: 100%;
      color: #fff;
      font-size: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .close-container {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
      width: 90px;
      top: 5px;
      left: 86%;
      cursor: pointer;
      .close-button {
        position: absolute;
        z-index: 2;
        width: 58px;
        height: 58px;
        border-radius: 100%;
        border: 4px solid #cf707f;
        cursor: pointer;
        display: flex;
        transition: all 0.25s ease;
        align-items: center;
        justify-content: center;
        &:active {
          filter: brightness(60%);
          -webkit-filter: brightness(60%);
        }
        .exit-icon {
          transform: scale(0.7);
        }
      }
    }
  }
}
.friends-detail {
  position: relative;
  padding-left: 90px;
  height: 950px;
  overflow-y: scroll;
  padding-top: 25px;

  .player-window-avatar {
    margin-left: 162px;
  }
  .user-country {
    position: absolute;
    top: 78px;
    left: 104px;
    width: 99px !important;
  }
  .username-value {
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 75px;
    margin-top: 16px;
  }
  // .add-friends-button {
  //   position: absolute;
  //   top: 193px;
  //   left: 759px;
  //   z-index: 9;
  // }
  span .username-value {
    font-size: 40px;
  }
  .input-line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 80px;
    width: 520px;
    background: #6e1b26;
    border-radius: 50px;
    margin-top: 30px;
    .user-id-text {
      font-size: 35px;
    }
    .id-value {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .chip-value {
      text-align: center;
      width: 100%;
      font-size: 35px;
      padding-right: 30px;
    }
    .red-content {
      height: 45px;
      width: 45px;
      border-radius: 23px;
      background: rgb(127, 30, 29);
      background: linear-gradient(
        0deg,
        rgb(127, 30, 29) 0%,
        rgb(177, 47, 48) 100%
      );
      border: solid 1px #1e0809;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      left: 10px;
    }
    .click-area {
      cursor: pointer;
      width: 70px;
      height: 70px;
    }
  }
  .user-id {
    justify-content: space-between;
    margin-bottom: 40px;
    .orange-content {
      height: 45px;
      width: 45px;
      border-radius: 23px;
      background: rgb(127, 30, 29);
      background: linear-gradient(0deg, #f49648 0%, #f17e32 100%);
      border: solid 1px #1e0809;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      right: 10px;
      &:active {
        transform: scale(1.1);
      }
      .tooltip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted black;
        bottom: 70px;
        right: 150px;
        .tooltiptext {
          width: 200px;
          height: 40px;
          background-color: black;
          color: #fff;
          text-align: center;
          padding: 5px 0;
          border-radius: 10px;
          position: absolute;
          left: 40px;
          font-size: 26px;
          z-index: 1;
        }
        .tooltiptext::after {
          content: " ";
          position: absolute;
          top: 100%; /* At the bottom of the tooltip */
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: black transparent transparent transparent;
        }
      }
    }
  }
  .friend-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    width: 540px;
    margin: 30px 0 100px 0;
    border-bottom: 1px solid #420d15;

    .block-button {
      font-size: 34px;
    }
    .message-button {
      .chat-icon {
        background: url("~@/assets/images/buttons/chat-button.png") no-repeat
          50% 50%;
        width: 83px;
        height: 86px;
        border-radius: 10px;
        cursor: pointer;
      }
    }
    .add-friends-button {
      .add-friends-icon {
        background: url("~@/assets/images/profile/add-button.png");
        background-repeat: no-repeat;
        height: 90px;
        width: 84px;
        cursor: pointer;
      }
    }
    .chip-button {
      .chip-icon {
        background: url("~@/assets/images/buttons/send-chip-button.png")
          no-repeat 50% 50%;
        width: 83px;
        height: 86px;
        border-radius: 10px;
        cursor: pointer;
      }
    }
    .chat-text {
      position: absolute;
      top: 640px;
      font-size: 34px;
      color: #fff;
      text-shadow: 0 3px 5px #000000;
    }
    .remove-friend {
      &-icon {
        background: url("~@/assets/images/profile/delete-button.png");
        width: 83px;
        height: 86px;
        cursor: pointer;
      }
    }
  }
  .best {
    height: 245px !important;
  }
  .slider-part {
    position: absolute;
    right: 70px;
    top: 170px;
    cursor: pointer;
    .circle-button {
      position: absolute;
      right: -2px;
      top: -5px;
      &.left {
        left: -3px;
      }
    }
    .blocked-status {
      font-size: 24px;
      margin-top: 15px;
      text-align: center;
    }
  }
  .friend-desc {
    margin-top: 20px;
    padding-bottom: 8px;
    width: 510px;
    border-bottom: 1px solid #420d15;
    .friend-desc-title {
      font-size: 40px;
      text-align: center;
      color: #f49748;
    }
    .friend-desc-number {
      font-size: 40px;
      word-wrap: break-word;
      text-align: center;
      margin-top: 10px;
    }
    .friend-desc-cards {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 600px;
      position: relative;
      right: 50px;
      height: 230px;
      .card {
        margin: 20px 10px 0 0;
      }
    }
  }
}
.friends-detail::-webkit-scrollbar-thumb {
  background: none !important;
}

.closeAnimate {
  animation-name: closeAnimation;
  animation-duration: 0.7s;
  animation-fill-mode: backwards;
}
@keyframes openAnimation {
  0% {
    transform: translateX(-500px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes closeAnimation {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-800px);
    display: none;
  }
}
</style>
